const bg = 'hsl(200, 60%, 15%)';
const height = "60px";

export const headerTemplate = `
<style>
  :root {
    --distill-header-height: ${height};
  }

  distill-header {
    position: sticky;
    top: 0;

    box-sizing: border-box;
    height: ${height};
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    color: rgba(0, 0, 0, 0.8);
    background-color: ${bg};
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }

  distill-header .content {
    height: inherit;
    grid-column: page;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #nav-logo-container {
    width: 100%;
    height: inherit;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  distill-header .content .hamburger-menu {
    display: flex;
  }

  distill-header .hamburger-menu .line {
    background-color: white;
  }

  #nav-wrapper {
    max-height: 0;

    position: fixed;
    left: 0;
    right: 0;
  }

  distill-header a {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
  }

  distill-header svg {
    width: 24px;
    position: relative;
    top: 4px;
    margin-right: 2px;
  }

  distill-header svg path {
    fill: none;
    stroke: rgba(255, 255, 255, 0.8);
    stroke-width: 3px;
  }
  distill-header .logo {
    font-size: 17px;
    font-weight: 200;

    display: flex;
    align-items: center;
    gap: 6px;
  }
  distill-header .nav {
    font-weight: 300;

    display: flex;
  }
  distill-header .nav a {
    margin-left: 24px;

    font-size: 12px;
    text-transform: uppercase;
  }

  distill-header a:hover {
    color: rgba(255, 255, 255, 1);
  }

  @media(max-width: 1080px) {
    distill-header .logo {
      font-size: 12px;
    }

    #nav-wrapper {
      top: ${height};
    }

    distill-header .content #nav-container {
      max-height: 0;
      background-color: ${bg};

      overflow: hidden;
      transition: max-height 500ms ease-in-out, border 500ms ease-in-out;
    }

    distill-header .content.open #nav-container {
      max-height: 100vh;
    }

    distill-header .nav {
      height: calc(100vh - ${height});
      padding-bottom: 24px;
      border-top: 1px solid #A9A9A9;

      flex-direction: column;
      align-items: start;
    }

    distill-header .nav a {
      width: 100%;
      margin-left: 0;

      padding: 16px;
      border-bottom: 1px solid #A9A9A9;
    }
  }

  @media(min-width: 1080px) {
    distill-header #nav-logo-container {
      width: fit-content;
    }

    distill-header .content .hamburger-menu {
      display: none;
    }

    #nav-wrapper {
      max-height: unset;

      position: relative;
    }

    #nav-container {
      height: 100%;
      display: flex;
      align-items: center;
    }

    distill-header .nav {
      float: right;
    }
  }
</style>
<div id="distill-header" class="content">
  <div id="nav-logo-container">
    <a href="/" class="logo">
      <img src="logo.svg" width="20px" style="margin-bottom: -4px">
      OpenDP Differential Privacy Deployments Registry
    </a>

    <div class="hamburger-menu" onClick="toggleHeaderNav(event)">
      <div class="line line-top"></div>
      <div class="line line-bottom"></div>
    </div>
  </div>

  <div id="nav-wrapper">
    <div id="nav-container">
      <nav class="nav">
        <a href="https://oblivious.com/">Oblivious</a>
        <a href="https://opendp.org/">OpenDP Home</a>
      </nav>
  </div>
  </div>
</div>
`;
