// import marked from 'marked';
// import katex from 'katex';
import 'katex/dist/katex.min.css';
import { renderMathInElement } from '../helpers/katex-auto-render';

import Prism from 'prismjs';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-lua';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-julia';
import css from 'prismjs/themes/prism.css';

import showdown from 'showdown';

const converter = new showdown.Converter({
        omitExtraWLInCodeBlocks: true,
        simplifiedAutoLink: true,
        tables: true,
        strikethrough: true,
        customizedHeaderId: true,
        ghCompatibleHeaderId: true,
        excludeTrailingPunctuationFromURLs: true,
        tablesHeaderId: true,
        tasklists: true,
    }
);

export class Markdown extends HTMLElement {
  
    static get is() { return 'd-markdown'; }

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        const path = this.getAttribute('path');
        this.renderMarkdown(path);
    }

    async renderMarkdown(path) {
        try {
            const response = await fetch(path);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const markdownText = await response.text();

            // Convert markdown to HTML using Showdown with Prism.js extension
            const htmlContent = converter.makeHtml(markdownText);

            // Set the innerHTML of the shadow DOM
            this.shadowRoot.innerHTML = `
<style>
    code {
        background: rgba(0, 0, 0, 0.04);
        border-radius: 2px;
        padding: 4px 7px;
        color: rgba(0, 0, 0, 0.6);
    }
    pre {
        padding: 8px !important;
    }
        
    ${css}
</style>

<div class="markdown-body">${htmlContent}</div>
`;

            // Render math equations using KaTeX
            renderMathInElement(this.shadowRoot.querySelector('.markdown-body'), {
                delimiters: [
                    { left: "$$", right: "$$", display: true },
                    { left: "$", right: "$", display: false }
                ]
            });

            // Highlight code blocks using Prism.js
            this.shadowRoot.querySelectorAll('pre code').forEach((block) => {
                Prism.highlightElement(block);
            });

        } catch (error) {
            console.error('Error fetching or processing the markdown file:', error);
        }
    }
}